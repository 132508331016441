/*eslint-disable*/
// chakra imports
import {
    Box,
    Button, Flex,
    Link,
    Stack,
    Text,
    useColorModeValue,
    Image,
  useDisclosure,
} from "@chakra-ui/react";
import { Separator } from "components/Separator/Separator";
import { SidebarHelp } from "components/Sidebar/SidebarHelp";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import LOGO from 'assets/logo.png'
import {PortfolioModal} from 'components/Portfolio/portfolioModal'

// this function creates the links and collapses that appear in the sidebar (left menu)
import WalletQR from 'assets/img/qr.jpg'

const SidebarContent = ({ logoText, routes }) => {
  const [val, setVal] = React.useState(2000)
  const { isOpen, onOpen, onClose } = useDisclosure()

    // to check for active links and opened collapses
  let location = useLocation();
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  const createLinks = (routes) => {
    // Chakra Color Mode
    const activeBg = useColorModeValue("white", "gray.700");
    const inactiveBg = useColorModeValue("white", "gray.700");
    const activeColor = useColorModeValue("gray.700", "white");
    const inactiveColor = useColorModeValue("gray.400", "gray.400");

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.category) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <div key={prop.name}>
            <Text
              color={activeColor}
              fontWeight="bold"
              mb={{
                xl: "12px",
              }}
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              py="12px"
            >
              {document.documentElement.dir === "rtl"
                ? prop.rtlName
                : prop.name}
            </Text>
            {createLinks(prop.views)}
          </div>
        );
      }
      return (
        <NavLink to={prop.layout + prop.path} key={prop.name}>
          {activeRoute(prop.layout + prop.path) === "active" ? (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              bg={activeBg}
              mb={{
                xl: "12px",
              }}
              mx={{
                xl: "auto",
              }}
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              py="12px"
              borderRadius="15px"
              _hover="none"
              w="100%"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              <Flex>
                <Text color={activeColor} style={{transform: "rotate(45deg)"}} >◼︎</Text>
                <Text color={activeColor} my="auto" fontSize="sm" pl={5}>
                  {document.documentElement.dir === "rtl"
                    ? prop.rtlName
                    : prop.name}
                </Text>
              </Flex>
            </Button>
          ) : (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              bg="transparent"
              mb={{
                xl: "12px",
              }}
              mx={{
                xl: "auto",
              }}
              py="12px"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              borderRadius="15px"
              _hover="none"
              w="100%"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              <Flex>
                <Text color={inactiveColor} style={{transform: "rotate(45deg)"}} >◻︎</Text>
                <Text color={inactiveColor} my="auto" fontSize="sm" pl={5}>
                  {document.documentElement.dir === "rtl"
                    ? prop.rtlName
                    : prop.name}
                </Text>
              </Flex>
            </Button>
          )}
        </NavLink>
      );
    });
  };

    const links = <>{createLinks(routes)}</>;

  return (
    <>
        <Box pt={"25px"} mb="12px">
      <Link
        href={`${process.env.PUBLIC_URL}/#/`}
        display="flex"
        lineHeight="100%"
        mb="30px"
        fontWeight="bold"
        justifyContent="center"
        alignItems="center"
        fontSize="11px"
      >
        <Image maxW={150}  src={LOGO}/>
      </Link>
      <Separator></Separator>
    </Box>
          <Stack direction="column" mb="40px">
            <Box>{links}</Box>
          </Stack>

          <Flex direction='column' alignItems='center'>
            <Text fontWeight='bold' fontSize={16}>Add & Withdraw Funds</Text>
            <Text fontSize={11} px='5'>Fill in the following forms to add or withdraw funds.</Text>
          
          <Button onClick={onOpen} w='48' my={4}  variant='solid' _dark={{bg:'white', color:'#0C1C2C'}} _light={{bg:'#0C1C2C', color:'white'  }} _hover={{opacity: 0.8}} rounded='full' >Deposit Funds</Button>
          <a href='https://t.me/MrWhiteWolf' target='_blank'>
          <Button  mb={10} w='48' variant='solid' _dark={{bg:'white', color:'#0C1C2C'}} _light={{bg:'#0C1C2C', color:'white'  }} _hover={{opacity: 0.8}} rounded='full'>Withdraw Funds</Button></a>
          <a href='https://t.me/MrWhiteWolf' target='_blank'>
          <Button w='48' variant='outline' rounded='full'>Contact Support</Button></a>
          </Flex>
          <PortfolioModal isOpen={isOpen} onClose={onClose} selectedId={15} />
          
    </>
  )
}

export default SidebarContent