import React, { useEffect, createContext, useState } from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider, Spinner, Flex } from '@chakra-ui/react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import useLocalStorage from 'useLocalStrorage'
import axios from 'axios'
import "animate.css/animate.min.css"

import AuthLayout from 'layouts/Auth.js'
import AdminLayout from 'layouts/Admin.js'
import LandingLayout from 'layouts/Landing.js'

import Dashboard from 'views/Dashboard/Dashboard'
import Transactions from 'views/Dashboard/Transactions'
import Portfolios from 'views/Dashboard/Portfolios'
import Resources from 'views/Dashboard/Resources'
import Profile from 'views/Dashboard/Profile'
import SignIn from 'views/Auth/SignIn.js'
import SignUp from 'views/Auth/SignUp.js'

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon
} from 'components/Icons/Icons'

const API_URL = 'https://monkfish-app-hcsp4.ondigitalocean.app/api'


export const AppState = createContext()

const App = () => {
  const LoggedInRoutes = [
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      layout: '/admin'
    },
    {
      path: '/portfolios',
      name: 'Portfolios',
      component: Portfolios,
      layout: '/admin'
    },
    {
      path: '/transactions',
      name: 'History',
      component: Transactions,
      layout: '/admin'
    },
    
    {
      path: '/resources',
      name: 'Resources',
      component: Resources,
      layout: '/admin'
    },
    {
      path: '/profile',
      name: 'Profile',
      secondaryNavbar: true,
      component: Profile,
      layout: '/admin'
    }
  ]
  const LoggedOutRoutes = [
    {
      path: '/',
      name: 'Sign In',
      icon: <DocumentIcon color='inherit' />,
      component: SignIn,
      layout: '/auth'
    }
  ]
  const [restoringSession, setRestoringSession] = useState(true)
  const [state, setState] = useState({ routes: LoggedOutRoutes, user: null})
  useEffect(() => {
    const savedJWT = localStorage.getItem('JWT')
    if(savedJWT){
      axios.get(API_URL + '/users/me?populate[0]=payments&populate[1]=withdrawals&populate[2]=payments.relatedPortfolio&populate[3]=withdrawals.relatedPortfolio', { headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + savedJWT } }).then(resp => {
        const user = { token: savedJWT, ...resp.data }
        user.subscribedTo = user.payments.filter(payment => payment.paymentStatus !== 'Not Paid').map(i => ({...i.relatedPortfolio})).reduce((cumm, curr)=> {
          if(!cumm.some(item => item.id === curr.id)){
            cumm.push(curr)
          }
          return cumm
        },[])
        setState({...state, user, routes: LoggedInRoutes})
        setRestoringSession(false)
      }).catch(e => {
        setRestoringSession(false)
        localStorage.clear()
      })
    } else {
      setRestoringSession(false)
    }
    
  }, [])

  if(restoringSession) {
    return(
      <ChakraProvider>
        <Flex w='full' justify='center' p='60'>
         <Spinner size='xl' />
        </Flex>
      </ChakraProvider>
    )
  }

  const asyncActions = {
    login: async (email, password) => {
      const headers = { 'Content-Type': 'application/json' }
      try {
        const resp = await axios.post(API_URL + '/auth/local', { identifier: email, password }, { headers })
        headers.Authorization = 'Bearer ' + resp.data.jwt
        const resp2 = await axios.get(API_URL + '/users/me?populate[0]=payments&populate[1]=withdrawals&populate[2]=payments.relatedPortfolio&populate[3]=withdrawals.relatedPortfolio', { headers })
        const user = { token: resp.data.jwt, ...resp2.data }
        user.subscribedTo = user.payments.filter(payment => payment.paymentStatus !== 'Not Paid').map(i => ({...i.relatedPortfolio})).reduce((cumm, curr)=> {
          if(!cumm.some(item => item.id === curr.id)){
            cumm.push(curr)
          }
          return cumm
        },[])
        setState({...state, user, routes: LoggedInRoutes})
        localStorage.setItem('JWT', resp.data.jwt)
      } catch (error) {
        throw error.response
      }
    },
    signOut: async () => {
      setState(({ routes: LoggedOutRoutes, user: null}))
      localStorage.clear()
    },
    httpGet: async (url) => {
      const headers = { 'Content-Type': 'application/json' }
      if (state.user.token) {
        headers.Authorization = 'Bearer ' + state.user.token
      }
      try {
        const resp = await axios.get(API_URL + url, { headers })
        return resp
      } catch (error) {
        console.log(error.response.data)
        throw new Error('Request Failed')
      }
    },
    httpPost: async (url, body) => {
      const headers = { 'Content-Type': 'application/json' }
      if (state.user.token) {
        headers.Authorization = 'Bearer ' + state.user.token
      }
      try {
        const resp = await axios.post(API_URL + url, body, { headers })
        return resp
      } catch (error) {
        console.log(error.response.data)
        throw new Error('Request Failed')
      }
    },
    httpPatch: async (url, body) => {
      const headers = { 'Content-Type': 'application/json' }
      if (state.user.token) {
        headers.Authorization = 'Bearer ' + state.user.token
      }
      try {
        const resp = await axios.patch(API_URL + url, body, { headers })
        return resp
      } catch (error) {
        console.log(error.response.data)
        throw new Error('Request Failed')
      }
    },
    httpDelete: async (url) => {
      const headers = { 'Content-Type': 'application/json' }
      if (state.user.token) {
        headers.Authorization = 'Bearer ' + state.user.token
      }
      try {
        const resp = await axios.delete(API_URL + url, { headers })
        return resp
      } catch (error) {
        console.log(error.response.data)
        throw new Error('Request Failed')
      }
    }
  }

  return (
    <ChakraProvider>
      <AppState.Provider value={{ ...state, ...asyncActions }}>
        <Router>
          {state.user
            ? (
              <Switch>
                <Route path='/admin'>
                  <AdminLayout />
                </Route>
                <Route path='*'>
                  <Redirect to='/admin' />
                </Route>
              </Switch>
              )
            : (
              <Switch>
                <Route exact path='/'>
                  <LandingLayout />
                </Route>
                <Route path='/auth'>
                  <AuthLayout />
                </Route>
                <Route path='*'>
                  <Redirect to='/' />
                </Route>
              </Switch>
              )}
        </Router>
      </AppState.Provider>
    </ChakraProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
