// Chakra imports
import { Tabs, Tab, TabList, TabPanels, TabPanel, Text } from '@chakra-ui/react'
import ReactApexChart from 'react-apexcharts'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import React, { useContext, useEffect, useState } from 'react'
import { AppState } from 'index'

const ActiveUsers = () => {
  const { user } = useContext(AppState)
  const [data, setData] = useState([])
  useEffect(() => {
    console.log(user.subscribedTo)
    setData(user.subscribedTo.filter(p => new Date() > new Date(p.portfolioStartDate) && new Date() < new Date(p.portfolioEndDate)).map(s => ({
      name: s.name,
      series: s.assetAllocation.split(',').map(alloc => parseFloat(alloc.split(':')[1])),
      labels: s.assetAllocation.split(',').map(alloc => alloc.split(':')[0])
    })))
  }, [])
  return (
    <Card>
      <CardBody>
      {data.length > 0 ?  <Tabs style={{ width: '100%' }} variant='enclosed'>
          <TabList>
            {
              data.map((item, i) => (
                <Tab key={i}>{item.name}</Tab>
              ))
            }
          </TabList>
          <TabPanels>
            {
               data.map((item, i) => (
                 <TabPanel key={i} style={{ width: '100%', height: 400 }}>
                   <ReactApexChart
                     series={item.series}
                     options={{
                       chart: {
                         type: 'donut'
                       },
                       title: {
                         text: 'Asset Allocation'
                       },
                       labels: item.labels,
                      theme:{
                        mode: 'light'
                      },
                      legend: {onItemHover:{highlightDataSeries:false}},
                      responsive: [{
                        breakpoint: 480,
                        options: {
                          chart: {
                            width: '100%'
                          },
                          legend: {
                            position: 'bottom'
                          
                          }
                        }
                      }]
                     }}
                     type='donut'
                     width='100%'
                     height='100%'
                   />
                 </TabPanel>
               ))
            }
          </TabPanels>
        </Tabs>: (<Text textAlign={'center'} p={10} w={'100%'}>No Invested Active Portfolios</Text>)}

      </CardBody>
    </Card>
  )
}

export default ActiveUsers
