// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react'
// Custom components
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import PortfolioItem from 'components/Tables/PortfolioItem'
import React, { useState, useContext, useEffect } from 'react'
import { AppState } from 'index'

const Portfolios = () => {
  const currDate = new Date()
  const { httpGet, user } = useContext(AppState)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [allPayments, setPayments] = useState([])
  const load = async () => {
    try {
      const resp = await httpGet('/portfolios')
      const resp2 = await httpGet('/users?populate[0]=payments&populate[1]=payments.relatedPortfolio')
      
      setPayments(resp2.data
        .reduce((cumm, u) => (
          [
            ...cumm,
            ...u.payments
            .filter(pay => (
              pay.paymentStatus !== "Not Paid" && pay.paymentStatus !== "Processing"
            ))
          ]
        ),[])
      )
      
      setData(resp.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  useEffect(() => {
    load()
  }, [])
  return (
    <Card>
      <Flex direction='column'>
        <CardHeader py='12px'>
          <Text color={useColorModeValue('gray.700', 'white')} fontSize='lg' fontWeight='bold' pb={5}>
            {loading ? 'Loading...' : 'Available Portfolios'}
          </Text>
        </CardHeader>
        <CardBody>
          <Flex direction='column' w='100%'>
            {data.filter(p => currDate > new Date(p.attributes.poolingStartDate) && currDate < new Date(p.attributes.portfolioEndDate)).map((row, i) => {
              
              return (
                <PortfolioItem
                  key={i}
                  attr={row.attributes}
                  pid={row.id}
                  allPayments={allPayments}
                  user={user}
                />
              )
            })}
          </Flex>
        </CardBody>
      </Flex>
    </Card>
  )
}

export default Portfolios
