import { NavLink } from 'react-router-dom'
import {
  chakra,
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  IconButton,
  CloseButton,
  useColorModeValue,
  useDisclosure,
  VStack,
  useColorMode,
  AspectRatio,
  Modal, ModalBody, ModalContent, ModalOverlay, ModalCloseButton
} from '@chakra-ui/react'
import { AiOutlineMenu } from 'react-icons/ai'
import { BsSun, BsMoonStarsFill, BsFillPlayCircleFill, BsStarFill, BsStarHalf } from 'react-icons/bs'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import LOGO from 'assets/logo.png'
import FeatureIMG from 'assets/img/feature.png'
import TrustPilotIMG from 'assets/img/trustpilot.png'
import DashboardIMG from 'assets/img/dashboard.png'
import P1IMG from 'assets/img/Portfolios1.png'
import P2IMG from 'assets/img/Portfolios2.png'
import P3IMG from 'assets/img/portfolios3.png'
import F1IMG from 'assets/img/feat1.png'
import F2IMG from 'assets/img/feat2.png'
import F3IMG from 'assets/img/feat3.png'
import AzukiIMG from 'assets/img/azuki.png'
import Azuki2IMG from 'assets/img/azuki2.png'
import WhyIMG from 'assets/img/whyimg.png'
import FAQIMG from 'assets/img/faqimg.png'
import arrowIMG from 'assets/img/arrow.png'
import Footer from 'components/Footer/Footer'
import { useState } from 'react';
import PYIMG from 'assets/img/youtubeimg.png'

const accordionItems = [
  {
    title: "What is Pool Me?",
    content: "Pool Me helps average investors get exposed to competitive crypto markets by leveraging the expertise and tools of a specialized team, minimizing risks, and managing funds effectively."
  },
  {
    title: "How does Pool Me work?",
    content: "Create an account, wait for verification, choose a portfolio, and let our team manage the rest."
  },
  {
    title: "Is Pool Me safe and secure?",
    content: "Yes, Pool Me uses best practices in managing crypto and owns all the keys for wallet operations."
  },
  {
    title: "Who manages the pooled funds?",
    content: "A specialized team of traders, researchers, and industry experts manages the pooled funds."
  },
  {
    title: "What are the benefits of pooling funds with Pool Me?",
    content: "Pool Me actively manages investments to lower risks and increase rewards. The pool is monitored 24 hours a day."
  },
  {
    title: "Are there any fees associated with using Pool Me?",
    content: "Fees differ between pools and include management fees, performance fees, and OTC fees for select users."
  },
  {
    title: "How can I track the performance of my pooled funds?",
    content: "Track performance on your dashboard. The NAV of the portfolio is updated once a day at 5:00 AM UTC+3, and sometimes multiple times a day."
  },
  {
    title: "Can I withdraw my funds at any time?",
    content: "You can withdraw funds available in your unlocked wallet at any time. Processing withdrawals can take up to 24 hours."
  },
  {
    title: "Are there any risks involved in pooling funds?",
    content: "Yes, there are many risks associated with investing in pools. For more information, submit an account creation form to see if you qualify."
  },
  {
    title: "What is the duration of each pooling cycle?",
    content: "On average, 7-14 days."
  },
  {
    title: "How do I receive my returns?",
    content: "You can receive returns in the cryptocurrency of your choice or by cash if you are in areas where we operate."
  }
];
export default function Landing () {
  const bg = useColorModeValue('white', 'gray.800')
  const mobileNav = useDisclosure()
  const { colorMode, toggleColorMode } = useColorMode()
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  return (
    <div>
      <>
        <chakra.header
          position='fixed'
          zIndex={99}
          bg={bg}
          w='full'
          px={{
            base: 2,
            sm: 4
          }}
          py={4}
          shadow='md'
        >
          <Flex alignItems='center' justifyContent='space-between' mx='auto'>
            <Flex>
              <chakra.a
                href='/'
                title='Choc Home Page'
                display='flex'
                alignItems='center'
              >
                <Image maxW={150}  src={LOGO}/>
              </chakra.a>

            </Flex>
            <HStack display='flex' alignItems='center' spacing={1}>
              <HStack
                spacing={1}
                mr={1}
                color='brand.500'
                display={{
                  base: 'none',
                  md: 'inline-flex'
                }}
              >
                <Button variant='ghost' as={'a'} href='#discover'>Discover</Button>
                <Button variant='ghost' as={'a'} href='#features'>Features</Button>
                <Button variant='ghost' as={'a'} href='#faq'>FAQ</Button>
                <NavLink to='/auth'><Button variant='solid'  _light={{bg:'#0C1C2C', color:'white'  }} _hover={{opacity: 0.8}} rounded='full'>Sign in</Button></NavLink>
                {/* <Button
                  onClick={toggleColorMode}
                  _focus={{ boxShadow: 'none' }}
                  w='fit-content'
                  variant='link'
                >
                  {colorMode === 'light' ? <BsMoonStarsFill /> : <BsSun />}
                </Button> */}
              </HStack>
              <Button colorScheme='brand' size='sm'>
                Get Started
              </Button>
              <Box
                display={{
                  base: 'inline-flex',
                  md: 'none'
                }}
              >
                <IconButton
                  display={{
                    base: 'flex',
                    md: 'none'
                  }}
                  aria-label='Open menu'
                  fontSize='20px'
                  color='gray.800'
                  _dark={{
                    color: 'inherit'
                  }}
                  variant='ghost'
                  icon={<AiOutlineMenu />}
                  onClick={() => mobileNav.onOpen()}
                />

                <VStack
                  pos='absolute'
                  top={0}
                  left={0}
                  right={0}
                  display={mobileNav.isOpen ? 'flex' : 'none'}
                  flexDirection='column'
                  p={2}
                  pb={4}
                  m={2}
                  bg={bg}
                  spacing={3}
                  rounded='sm'
                  shadow='sm'
                >
                  <CloseButton
                    aria-label='Close menu'
                    onClick={() => mobileNav.onClose()}
                  />

                  <Button as={'a'} href='#discover' w='full' variant='ghost'>
                    Dicover
                  </Button>
                  <Button as={'a'} href='#features' w='full' variant='ghost'>
                    Features
                  </Button>
                  <Button as={'a'} href='#faq' w='full' variant='ghost'>
                    FAQ
                  </Button>
                  <NavLink to='/auth'><Button w='full' variant='ghost'>
                    Sign in
                  </Button></NavLink>
                </VStack>
              </Box>
            </HStack>
          </Flex>
        </chakra.header>
      </>
      <Flex direction={{base:'column-reverse', lg: 'row'}} m='auto' maxW='8xl' alignItems='center' justifyContent='space-between' px='10' pt='32'>
  <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft">
    <Box>
      <Text 
        fontFamily='Inter'
        fontSize={56}
        fontWeight={500}
      >
        We Manage. You Earn!
      </Text>
      
      <Text
        fontFamily='Inter'
        fontSize={19}
        fontWeight={400}
        maxW='lg'
        color='#718096'
        p={3}
      >
        Effortlessly and securely invest in memecoins and cryptocurrencies with Pool Me's expert management.
      </Text>
      <Flex p={3}>
        <Button as={'a'} href='https://wkulpo6815w.typeform.com/to/aSNc8VEf' _dark={{bg:'white', color:'#0C1C2C'}} _light={{bg:'#0C1C2C', color:'white'  }} _hover={{opacity: 0.8}} variant='solid' rounded='full'>Get Started</Button>
        <Button variant='solid' rounded='full' mx={5} onClick={openPopup}> <BsFillPlayCircleFill/> &nbsp;&nbsp;How It Works</Button>
      </Flex>
    </Box>
  </AnimationOnScroll>
  <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight">
    <div>
      <div onClick={openPopup}>
          <Image w={{base:'100%', md:'2xl'}} src={PYIMG} borderRadius={30} cursor={'pointer'}/>
      </div>
      <Modal isOpen={isOpen} onClose={onClose} size='6xl' isCentered >
        <ModalOverlay  bg='blackAlpha.300'
      backdropFilter='blur(10px)'/>
        <ModalContent w={'100%'} borderRadius={20}>
          <ModalCloseButton color={'gray.100'} />
          <ModalBody h={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} w={'100%'} bg="#000" borderRadius={20}>
          <iframe
              style={{borderRadius:'40px', margin:5, width:'100%', height:'100%', aspectRatio:'16/9'}} width="1600px" height="900px"
              src={`https://www.youtube.com/embed/c0hWjYJGhS8?autoplay=1`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  </AnimationOnScroll>
</Flex>

      <Flex px='10' pt={180} m='auto' maxW='8xl'>
      <Flex w='full' direction={{base:'column', lg: 'row'}} bg='#0C1C2C' borderRadius='40px'>
      <Box p='5'>
          <Image w='5xl' src={DashboardIMG}/>
        </Box>
        <Box p='16'>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
          <Text 
            fontFamily='Inter'
            fontSize={{base:20, lg:40}}
            fontWeight={500}
            color='#EDF2F7'
          >
            Stay Updated About Your Portfolios
          </Text>
          <Text
            fontFamily='Inter'
            fontSize={{base:13, lg:19}}
            fontWeight={400}
            maxW='lg'
            color='#718096'
          >
            Monitor your investments and progress with our intuitive dashboard.
          </Text>
          <Flex pt={5}>
            <Button as={'a'} href='/auth' variant='solid' rounded='full'> Sign In</Button>
          </Flex>
          </AnimationOnScroll>
        </Box>
        
      </Flex>
      </Flex>

      <Box px={{base:3, md:'10'}} py={180} m='auto' maxW='8xl' id='discover' >
      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
      <Text
            fontFamily='Inter'
            fontSize={40}
            fontWeight={500}
            textAlign='center'
            pb={10}
            w='full'
          >
            Discover Your Ideal Investment Path
          </Text>
          </AnimationOnScroll>
      <Flex direction={{base:'column', lg: 'row'}} alignItems='center' justifyContent='space-between' >
      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
        <Box shadow='2xl' m={3} borderRadius='40px' overflow='hidden' textAlign='center'  h={600}>
        <Image w='full' src={P1IMG} m={0}/>
        <Text
            fontFamily='Inter'
            fontSize={23}
            fontWeight={500}
            p={3}
            pt={10}
            w='full'
          >
            Star Struck
          </Text>
        <Text
            fontFamily='Inter'
            fontSize={14}
            fontWeight={400}
            maxW='lg'
            color='#718096'
            p={3}
          >
           Explore top Solana memecoins and capitalize on the latest trends in the crypto world, guided by our expert management.
          </Text>
          <Button as={'a'} href='https://wkulpo6815w.typeform.com/to/aSNc8VEf' variant='solid' _dark={{bg:'white', color:'#0C1C2C'}} _light={{bg:'#0C1C2C', color:'white'  }} _hover={{opacity: 0.8}} rounded='full'>Join the Stars</Button>
        </Box>
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp" delay={300}>
        <Box shadow='2xl' m={3} borderRadius='40px' overflow='hidden' textAlign='center'  h={600}>
        <Image w='full' src={P2IMG} m={0}/>
        <Text
            fontFamily='Inter'
            fontSize={23}
            fontWeight={500}
            p={3}
            pt={10}
          >
            Green Gold
          </Text>
        <Text
            fontFamily='Inter'
            fontSize={14}
            fontWeight={400}
            maxW='lg'
            color='#718096'
            p={3}
          >
          Invest in celebrity-themed tokens and tap into the growing trend of star-powered cryptocurrencies for steady growth and reliable returns.
          </Text>
          <Button as={'a'} href='https://wkulpo6815w.typeform.com/to/aSNc8VEf' variant='solid' _dark={{bg:'white', color:'#0C1C2C'}} _light={{bg:'#0C1C2C', color:'white'  }} _hover={{opacity: 0.8}}  rounded='full'>Strike Gold</Button>
        </Box>
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp" delay={450}>
        <Box shadow='2xl' m={3} borderRadius='40px' overflow='hidden' textAlign='center' h={600}>
        <Image w='full' src={P3IMG} m={0}/>
        <Text
            fontFamily='Inter'
            fontSize={23}
            fontWeight={500}
            p={3}
            pt={10}
          >
            Hopping Horizons
          </Text>
        <Text
            fontFamily='Inter'
            fontSize={14}
            fontWeight={400}
            maxW='lg'
            color='#718096'
            p={3}
          >
          Dive into high-reward opportunities with Solana memecoins, navigating the dynamic market with our expert strategies.
          </Text>
          <Button as={'a'} href='https://wkulpo6815w.typeform.com/to/aSNc8VEf' mt={5} variant='solid' _dark={{bg:'white', color:'#0C1C2C'}} _light={{bg:'#0C1C2C', color:'white'  }} _hover={{opacity: 0.8}} rounded='full'>Expand Your Horizons</Button>
        </Box>
        </AnimationOnScroll>
      </Flex>
      </Box>

      <Box bg='#0C1C2C'>
      <Box px='10' py={100} m='auto' maxW='8xl'>
      <Text 
            fontFamily='Inter'
            fontSize={40}
            fontWeight={500}
            color='#EDF2F7'
            pb='10'
            
          >
            Get Started in Minutes
          </Text>
      <Flex w='full' direction={{base:'column', lg: 'row'}}>
      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight" >
      <Flex p='5'>
      <Text 
            fontFamily='Inter'
            fontSize={50}
            fontWeight={500}
            color='#718096'
            pr={5}
          >
            1.
          </Text>
          <Box>
          <Text 
            fontFamily='Inter'
            fontSize={25}
            fontWeight={500}
            color='#EDF2F7'
          >
            Sign Up
          </Text>
          <Text
            fontFamily='Inter'
            fontSize={19}
            fontWeight={400}
            maxW='lg'
            color='#718096'
          >
            Complete the qualification form, understand the associated risks, and get verified to create your account.
          </Text>
          </Box>
        </Flex>
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight" delay={300}>
        <Flex p='5'>
      <Text 
            fontFamily='Inter'
            fontSize={50}
            fontWeight={500}
            color='#718096'
            pr={5}
          >
            2.
          </Text>
          <Box>
          <Text 
            fontFamily='Inter'
            fontSize={25}
            fontWeight={500}
            color='#EDF2F7'
          >
            Choose portfolio
          </Text>
          <Text
            fontFamily='Inter'
            fontSize={19}
            fontWeight={400}
            maxW='lg'
            color='#718096'
          >
            Explore our diverse range of portfolios to select the one that best aligns with your investment goals and risk tolerance.
          </Text>
          </Box>
        </Flex>
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight" delay={450}>
        <Flex p='5'>
      <Text 
            fontFamily='Inter'
            fontSize={50}
            fontWeight={500}
            color='#718096'
            pr={5}
          >
            3.
          </Text>
          <Box>
          <Text 
            fontFamily='Inter'
            fontSize={25}
            fontWeight={500}
            color='#EDF2F7'
          >
            Invest
          </Text>
          <Text
            fontFamily='Inter'
            fontSize={19}
            fontWeight={400}
            maxW='lg'
            color='#718096'
          >
           Fund your chosen portfolio with your preferred payment method and watch your investments grow over time.
          </Text>
          </Box>
        </Flex>
        </AnimationOnScroll>
      </Flex>
      
        <Button as={'a'} href='https://wkulpo6815w.typeform.com/to/aSNc8VEf' m='6' variant='solid' rounded='full'>Join Now</Button>
      </Box>
      </Box>
      <Image maxW='sm' src={arrowIMG} m={0} p={0} ml='20%' /> 
      <Box px='10' py={180} m='auto' maxW='8xl' id='features' >
      <Text
            fontFamily='Inter'
            fontSize={40}
            fontWeight={500}
            textAlign='center'
            w='full'
          >
            Why Investors Choose Us
          </Text>
          <Text
            fontFamily='Inter'
            fontSize={14}
            fontWeight={400}
            maxW='2xl'
            textAlign='center'
            m='auto'
            color='#718096'
            p={3}
          >
           At Pool Me, we provide expert management and advanced tools to help you succeed in the competitive crypto market. Our secure, transparent process ensures your investments are handled with care. Choose Pool Me for professional strategies that maximize returns and minimize risks.
          </Text>
      <Flex direction={{base:'column', lg: 'row'}} alignItems='center' justifyContent='space-between' pt='20'>
<AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
      <Flex m={3} direction='column' textAlign='center' alignItems='center'>
        <Image maxW='sm' src={F1IMG} />
        <Text
            fontFamily='Inter'
            fontSize={23}
            fontWeight={500}
            p={3}
            pt={10}
            w='full'
          >
            Trusted
          </Text>
        <Text
            fontFamily='Inter'
            fontSize={14}
            fontWeight={400}
            maxW='sm'
            color='#718096'
            p={3}
          >
           Benefit from our established track record and personalized portfolio strategies.
          </Text>
        </Flex>
</AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp" delay={300}>
        <Flex m={3} direction='column' textAlign='center' alignItems='center'>
        <Image maxW='sm' src={F2IMG} />
        <Text
            fontFamily='Inter'
            fontSize={23}
            fontWeight={500}
            p={3}
            pt={10}
            w='full'
          >
            Effortless
          </Text>
        <Text
            fontFamily='Inter'
            fontSize={14}
            fontWeight={400}
            maxW='sm'
            color='#718096'
            p={3}
          >
           Navigate our user-friendly dashboard and choose from a range of flexible plans.
          </Text>
        </Flex>
</AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp" delay={450}>
        <Flex m={3} direction='column' textAlign='center' alignItems='center'>
        <Image maxW='sm' src={F3IMG} />
        <Text
            fontFamily='Inter'
            fontSize={23}
            fontWeight={500}
            p={3}
            pt={10}
            w='full'
          >
            Safe
          </Text>
        <Text
            fontFamily='Inter'
            fontSize={14}
            fontWeight={400}
            maxW='sm'
            color='#718096'
            p={3}
          >
           Rely on our commitment to capital protection and focus on long-term returns for your investments.
          </Text>
        </Flex>
        </AnimationOnScroll>
      </Flex>
      </Box>

      <Flex px='10' pt={180} m='auto' maxW='8xl'>
      <Flex w='full' direction={{base:'column-reverse', lg: 'row'}} bg='#0C1C2C' justifyContent='space-around' borderRadius='40px' >
    
         <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft">
        <Box py='16' textAlign='center'>
          <Text 
            fontFamily='Inter'
            fontSize={40}
            fontWeight={500}
            color='#EDF2F7'
            textAlign='center'
            pb={10}
          >
            Explore our Memes <br/> Portfolios
          </Text>
          <Button as={'a'} href='/auth' variant='solid' rounded='full'>Explore Our Portfolios</Button>
          
        </Box></AnimationOnScroll>
      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight">
        <Image mt='-40' src={AzukiIMG}/> </AnimationOnScroll>
      </Flex>
      </Flex>

      <Flex direction={{base:'column', lg: 'row'}} m='auto' maxW='8xl' alignItems='center' justifyContent='space-between' px='10' pt={180}>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft">
          <Image w='xl' src={WhyIMG}/>
        </AnimationOnScroll>
      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight">
        <Box>
          <Text 
            fontFamily='Inter'
            fontSize={40}
            fontWeight={500}
          >
            Invest On the Go
          </Text>
          <Text
            fontFamily='Inter'
            fontSize={19}
            fontWeight={400}
            maxW='lg'
            color='#718096'
            p={3}
          >
            Access your portfolio anytime, anywhere with our mobile-friendly dashboard.
          </Text>
          <Flex p={3}>
          <Button as={'a'} href='https://wkulpo6815w.typeform.com/to/aSNc8VEf' _dark={{bg:'white', color:'#0C1C2C'}} _light={{bg:'#0C1C2C', color:'white'  }} _hover={{opacity: 0.8}}  variant='solid' rounded='full'>Get Started</Button>
            
          </Flex>
        </Box>
         </AnimationOnScroll>
      </Flex>

      <Box bg='#0C1C2C'>
      <Box px='10' py={100} m='auto' maxW='8xl' >
      <Text 
            fontFamily='Inter'
            fontSize={40}
            fontWeight={500}
            color='#EDF2F7'
            pb='10'
            textAlign='center'
          >
            What Clients Say
          </Text>
      <Flex w='full' direction={{base:'column', lg: 'row'}}>
      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
      <Box p='5' textAlign='center' m='5' border='1px' borderColor='#718096' borderRadius='40px'  h={320}>
          <Text 
            fontFamily='Inter'
            fontSize={25}
            fontWeight={500}
            color='#EDF2F7'
          >
            Pepe the Frog
            

          </Text>
          <Flex color='#EDF2F7' justifyContent='center'><BsStarFill/><BsStarFill/><BsStarFill/><BsStarFill/><BsStarHalf/></Flex>
          <Text
            fontFamily='Inter'
            fontSize={19}
            fontWeight={400}
            maxW='md'
            color='gray.300'
            py='10'
            fontStyle='italic'
          >
            Pool Me turned my ribbits into riches! Now I have more time to hop around and less time stressing over crypto. Feels good, man!
          </Text>
          </Box>
</AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp" delay={300}>
          <Box p='5' textAlign='center' m='5' border='1px' borderColor='#718096' borderRadius='40px'  h={320}>
          <Text 
            fontFamily='Inter'
            fontSize={25}
            fontWeight={500}
            color='#EDF2F7'
          >
            Doge
            

          </Text>
          <Flex color='#EDF2F7' justifyContent='center'><BsStarFill/><BsStarFill/><BsStarFill/><BsStarFill/><BsStarFill/></Flex>
          <Text
            fontFamily='Inter'
            fontSize={19}
            fontWeight={400}
            maxW='md'
            color='gray.300'
            py='10'
            fontStyle='italic'
          >
            Much invest. Very profit. Wow! Pool Me made my shibes very happy with easy and secure crypto gains. To the moon!
          </Text>
          </Box>
          </AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp" delay={450}>
          <Box p='5' textAlign='center' m='5' border='1px' borderColor='#718096' borderRadius='40px' h={320}>
          <Text 
            fontFamily='Inter'
            fontSize={25}
            fontWeight={500}
            color='#EDF2F7'
          >
            Grumpy Cat
            

          </Text>
          <Flex color='#EDF2F7' justifyContent='center'><BsStarFill/><BsStarFill/><BsStarFill/><BsStarFill/><BsStarHalf/></Flex>
          <Text
            fontFamily='Inter'
            fontSize={19}
            fontWeight={400}
            maxW='md'
            color='gray.300'
            py='10'
            fontStyle='italic'
          >
            I expected to hate it, but even I have to admit, Pool Me knows what they're doing. Managed to turn my frown into a crypto crown. Meh, it's alright.
          </Text>
          </Box>
       </AnimationOnScroll>
      </Flex>
      </Box>
      </Box>

      <Box px='10' py={180} m='auto' maxW='8xl' id='faq'>
      <Text
            fontFamily='Inter'
            fontSize={40}
            fontWeight={500}
            textAlign='center'
            w='full'
          >
            Frequently Asked Questions
          </Text>
          
      <Flex direction={{base:'column-reverse', lg: 'row'}} pt='10' alignItems={'center'}>
     
      <Accordion defaultIndex={0} flex={1}>
      {accordionItems.map((item, index) => (
        <AccordionItem
          key={index}
          border="1px solid"
          borderColor="gray.200"
         borderRadius={'3xl'}
          mb={2}
          w="90%"
        >
          <h2>
            <AccordionButton _expanded={{ bg: "#0C1C2C", color:'white', borderTopLeftRadius:'3xl', borderTopRightRadius:'3xl' }} >
              <Box flex="1" textAlign="left" fontWeight="bold">
                {item.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} >
            {item.content}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
    <Flex flex={1} direction={'column'} alignItems={'center'} my={3}> <Image w={'70%'}   src={FAQIMG} /> </Flex>
         
      </Flex>
      </Box>

      <Flex px='10' pt={0} m='auto' maxW='8xl' mb={'36'}>
      <Flex w='full' direction={{base:'column', lg: 'row'}} _light={{bg:'#EDF2F7', color:'#0C1C2C'}} _dark={{bg:'#0C1C2C', color:'white'  }} justifyContent='space-between' borderRadius='40px'><AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft">
      <Image mt={-110} src={Azuki2IMG} />
         </AnimationOnScroll>
      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight">
        <Box py='16' textAlign='center'>
          <Text 
            fontFamily='Inter'
            fontSize={40}
            fontWeight={500}
            textAlign='center'
            pb={10}
            px={5}
          >
            Discover High-Performace Pools
          </Text>
          <Button as={'a'} href='https://wkulpo6815w.typeform.com/to/aSNc8VEf' _dark={{bg:'white', color:'#0C1C2C'}} _light={{bg:'#0C1C2C', color:'white'  }} _hover={{opacity: 0.8}}  variant='solid' rounded='full'>Dive into Pool Me</Button>
          
        </Box>
         </AnimationOnScroll>
      </Flex>
      </Flex>

      <Footer/>
      </div>
  )
}

