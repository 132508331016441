
// Chakra Imports
import {
  Button,
  Flex,
  Text,
  useColorModeValue,

  useColorMode
} from '@chakra-ui/react'
import { BellIcon } from '@chakra-ui/icons'
import { useHistory } from 'react-router-dom'
// Assets
// Custom Icons
import { BsSunFill, BsMoonStarsFill } from 'react-icons/bs'
// Custom Components
import SidebarResponsive from 'components/Sidebar/SidebarResponsive'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { AppState } from 'index'

export default function HeaderLinks (props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props
  const { routes, signOut } = useContext(AppState)
  const history = useHistory()
  let navbarIcon = useColorModeValue('gray.500', 'gray.200')
  const { colorMode, toggleColorMode } = useColorMode()

  if (secondary) {
    navbarIcon = 'white'
  }
  const settingsRef = React.useRef()
  return (
    <Flex
      w='100%'
      alignItems='center'
      p={10}
      justifyContent={'space-between'}
      flexDirection='row-reverse'
    >

      <Button
        onClick={() => {
          history.push('/auth/signin')
          signOut()
        }}
        variant='solid' _dark={{bg:'white', color:'#0C1C2C'}} _light={{bg:'#0C1C2C', color:'white'  }} _hover={{opacity: 0.8}} rounded='full' display={{base:'none', sm:'block'}}
      >
        <Text >Sign Out</Text>
      </Button>
      
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        // logo={logo}
        {...rest}
      />
    </Flex>
  )
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func
}
