// Chakra imports
import { Flex, Box,TabList,Tabs,
  Tab,
  TabPanels ,TabPanel} from '@chakra-ui/react'
import React, { useContext, useState, useEffect } from 'react'
import TrxTable from './components/TrxTable'
import AllPortfoliosTable from './components/AllPortfoliosTable'
import { tablesTableData, dashboardTableData } from 'variables/general'
import { AppState } from 'index'
import avatar1 from 'assets/img/avatars/avatar1.png'

function Tables () {
  const { httpGet, user } = useContext(AppState)
  const [data, setData] = useState([]);
  const load = async () => {
    try {
      const resp = await httpGet('/portfolios?populate=*');
      setData(resp.data.data);
    } catch (error) {
      
    }
  };
  useEffect(()=>{
    load();
  },[])
  

  return (
    <Flex direction='column' alignItems={'center'} pt={{ base: '120px', md: '75px' }} >
      <Box maxW={'8xl'} w='100%'>
      <Tabs isFitted >
  <TabList mb='1em'>
    <Tab>All Transactions</Tab>
    <Tab>All Portfolios</Tab>
  </TabList>
  <TabPanels>
    <TabPanel p={0}>
    <TrxTable
        title='All Transactions'
        captions={['Related Portfolio',, 'Status', 'Due Date', 'Paid At', 'Type', 'Amount', 'Notes']}
        data={[...user.payments,...user.withdrawals]}
      />
    </TabPanel>
    <TabPanel p={0}>
    <AllPortfoliosTable
        title='All Portfolios'
        captions={['Portfolio Name', 'Status', 'Start Date', 'End Date', 'Performance']}
        data={data}
      />
    </TabPanel>
  </TabPanels>
</Tabs>
     
      
      </Box>
    </Flex>
  )
}

export default Tables
