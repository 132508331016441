import React, { useState, useContext, useEffect } from 'react';
import { Flex,Tag, Box, SkeletonCircle, SkeletonText, Text, Button, useDisclosure, Progress } from "@chakra-ui/react";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { AppState } from 'index';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const getTimeRemaining = (endDate) => {
  const total = endDate - new Date();
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  return `${days}d ${hours}h ${minutes}m`;
};

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentId, setContentId] = useState(null);

  const openModal = (id) => {
    setContentId(id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setContentId(null);
  };

  return {
    isOpen,
    contentId,
    openModal,
    closeModal
  };
};


import {PortfolioModal} from 'components/Portfolio/portfolioModal'
const Portfolios = () => {

  const currDate = new Date();
  const { httpGet, user } = useContext(AppState);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isOpen, contentId, openModal, closeModal } = useModal();

  const [allPayments, setPayments] = useState([])
  const load = async () => {
    try {
      const resp = await httpGet('/portfolios?populate=*');
      const resp2 = await httpGet('/users?populate[0]=payments&populate[1]=payments.relatedPortfolio')
      
      setPayments(resp2.data
        .reduce((cumm, u) => (
          [
            ...cumm,
            ...u.payments
            .filter(pay => (
              pay.paymentStatus !== "Not Paid" && pay.paymentStatus !== "Processing"
            ))
          ]
        ),[])
      )
      
      setData(resp.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(()=>{
    load();
  },[])


  return (
    <Flex direction={'column'} justifyContent={'space-evenly'} pt={5}  h={'80vh'}>
      <Text fontFamily='Inter' fontSize={{ base: 20, md: 40 }} fontWeight={500}>
        Discover More Portfolios and their Updates
      </Text>
      {loading && (
        <Flex width={'100%'} justifyContent={'space-evenly'} py={10}>
        <Box padding='6' boxShadow='lg' bg='white' w='30%'>
          <SkeletonCircle size='10' />
          <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
        </Box>
        <Box padding='6' boxShadow='lg' bg='white'  w='30%'>
          <SkeletonCircle size='10' />
          <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
        </Box>
        <Box padding='6' boxShadow='lg' bg='white'  w='30%'>
          <SkeletonCircle size='10' />
          <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
        </Box>
        </Flex>
      )}
      
      <div className="expanding-collection">
      
        <Swiper
          speed={600}
          resistanceRatio={0}
          centeredSlides={true}
          navigation={true}
          modules={[Navigation]}
          slidesPerView={1}
          spaceBetween={10}
          // Responsive breakpoints
          breakpoints= {{
            600: {
              slidesPerView: 2,
              spaceBetween: 10
            },
            800: {
              slidesPerView: 3,
              spaceBetween: 10
            },
            2300: {
              slidesPerView: 4,
              spaceBetween: 10
            }
          }}
        >
          {data.filter(i => i.id != 15 && currDate < new Date(i.attributes.portfolioEndDate)).sort((a,b)=>new Date(a.attributes.poolingStartDate)-new Date(b.attributes.poolingStartDate)).map((p, ind) => (<>
            <SwiperSlide key={ind}>
              <Box maxW={'md'} display={'flex'}  flexDirection={'column'} alignItems={'center'} bg={'white'} rounded={30}>
              <Box className="expanding-collection-cover" >
                  <img src={p.attributes.bannerImage.data.attributes.url} alt={p.attributes.name} style={{borderTopRightRadius:30, borderTopLeftRadius:30, cursor:'pointer'}} onClick={() => openModal(parseInt(p.id))} />
                  {currDate < new Date(p.attributes.poolingStartDate) ? (
                    <div className="demo-cover-coords">
                      <Tag bg={'orange.300'}>Coming Soon</Tag>
                      <Tag>Pooling starts in {getTimeRemaining(new Date(p.attributes.poolingStartDate))}</Tag>
                    </div>
                  ) : currDate < new Date(p.attributes.portfolioStartDate) ? (
                    <div className="demo-cover-coords">
                      <Tag bg={'blue.300'}>Pooling</Tag>
                      <Tag>Active in {getTimeRemaining(new Date(p.attributes.portfolioStartDate))}</Tag>
                    </div>
                  ) : currDate < new Date(p.attributes.portfolioEndDate) ? (
                    <div className="demo-cover-coords">
                      <Tag bg={'green.300'}>Active</Tag>
                      <Tag>Portfolio end in {getTimeRemaining(new Date(p.attributes.portfolioEndDate))}</Tag>
                      
                    </div>
                  ) : currDate < new Date(p.attributes.portfolioUnlockDate) ? (
                    <div className="demo-cover-coords">
                      <Tag>Completed</Tag>
                      <Tag>Funds Unlocked in {getTimeRemaining(new Date(p.attributes.portfolioUnlockDate))}</Tag>
                    </div>
                  ) : (
                    <div className="demo-cover-coords">
                      <Tag>Unlocked</Tag>
                    </div>
                  )}
                </Box>
                <Box w={'full'}  px={"10px"}>
                 
                  <Text fontSize={'lg'} fontWeight={'bold'}>{p.attributes.name}</Text>
                      <Text color='#0C1C2C' fontSize='sm'>
                        Management Fees:{' '}
                        <Text as='span' fontWeight='bold'>
                          {p.attributes.managementFees} %
                        </Text>
                      </Text>
                      <Text color='#0C1C2C' fontSize='sm'>
                        Performance Fees:{' '}
                        <Text as='span' fontWeight='bold'>
                          {p.attributes.performanceFees} %
                        </Text>
                      </Text>
                    <Text color='#0C1C2C' fontSize='sm'>
                        Lockup Period:{' '}
                        <Text as='span' fontWeight='bold'>
                          {p.attributes.lockupPeriodDescription}
                        </Text>
                      </Text>
                      {currDate < new Date(p.attributes.portfolioStartDate) && 
                      <>
                      <Text color='#0C1C2C' fontSize='sm'>
                        Raised:{' '}
                        <Text as='span' fontWeight='bold'>
                          ${(allPayments.filter(v => v.relatedPortfolio.id == p.id).reduce((cumm,curr)=> cumm+curr.amount,0)).toLocaleString()} / {(p.attributes.poolingTargetRaise).toLocaleString()}
                        </Text>
                      </Text>
                      <Progress colorScheme='green' hasStripe value={(((allPayments.filter(v => v.relatedPortfolio.id == p.id).reduce((cumm,curr)=> cumm+curr.amount,0))/parseInt(p.attributes.poolingTargetRaise)) *100)} rounded={'full'} />
                      </>}
                    <Button  mb={'10px'} mt={5} w={'100%'} variant='solid' bg='#0C1C2C' color='white' rounded='full' disabled={currDate < new Date(p.attributes.poolingStartDate) || currDate > new Date(p.attributes.portfolioStartDate)} onClick={() => openModal(parseInt(p.id))} >Invest</Button>
                  
                      
                      
                </Box>
               
              </Box>
              
            </SwiperSlide>
            </>
          ))}
        </Swiper>
        <PortfolioModal isOpen={isOpen} onClose={closeModal} selectedId={contentId} />
      </div>
    </Flex>
  );
};

export default Portfolios;
