// Chakra imports
import { Flex, useColorModeValue } from '@chakra-ui/react'
import avatar4 from 'assets/img/avatars/avatar4.png'
import ProfileBgImage from 'assets/img/ProfileBackground.png'
import { AppState } from 'index'
import React, { useContext, useState, useEffect } from 'react'
import { FaCube, FaPenFancy } from 'react-icons/fa'
import { IoDocumentsSharp } from 'react-icons/io5'
// import Conversations from './components/Conversations'
import Header from './components/Header'
// import PlatformSettings from './components/PlatformSettings'
import ProfileInformation from './components/ProfileInformation'
import Authors from './components/Authors'

function Profile () {
  const { httpGet, user } = useContext(AppState)
  const [data, setData] = useState([])
  const load = async () => {
    try {
      const resp = await httpGet('/users?populate[0]=referredBy')
      
      setData(resp.data
      .filter(u => u.referredBy ? u.referredBy.id === user.id : false)
      .map(u => ({
        id: u.id, 
        amount:(0.5 * (u.payments
                  .filter(payment => payment.paymentStatus !== 'Not Paid')
                  .reduce((cumm,curr)=> cumm + curr.amount,0)))
      })))
     
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    load()
  }, [])
  const bgProfile = useColorModeValue(
    'hsla(0,0%,100%,.8)',
    'linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)'
  )

  return (
    <Flex direction='column'>
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={avatar4}
        name={user.fullName}
        email={user.email}
        tabs={[
          {
            name: 'OVERVIEW',
            icon: <FaCube w='100%' h='100%' />
          },
          {
            name: 'Edit',
            icon: <IoDocumentsSharp w='100%' h='100%' />
          }
        ]}
      />
      {/* <Grid templateColumns={{ sm: "1fr", xl: "repeat(3, 1fr)" }} gap='22px'> */}
      {/* <PlatformSettings
          title={"Platform Settings"}
          subtitle1={"ACCOUNT"}
          subtitle2={"APPLICATION"}
        /> */}
      <ProfileInformation
        title='Profile Information'
        description={user.paymentDetails}
        name={user.fullName}
        mobile={user.phone}
        email={user.email}
        location={user.address}
      />

      {/* <Authors
        title='Refferals'
        captions={['RefferalID', 'Referral Bonus Earned']}
        data={data}
      /> */}
    </Flex>
  )
}

export default Profile
