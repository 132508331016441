// Chakra Imports
import {
  Box,
  Heading,
  Flex,
  useColorModeValue
} from '@chakra-ui/react'
import React, { useState } from 'react'
import AdminNavbarLinks from './AdminNavbarLinks'

export default function AdminNavbar (props) {
  const [scrolled, setScrolled] = useState(false)

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let navbarPosition = 'absolute'
  let navbarFilter = 'none'
  let navbarBackdrop = 'blur(21px)'
  let navbarShadow = 'none'
  let navbarBg = 'none'
  let navbarBorder = 'transparent'
  let secondaryMargin = '0px'
  let paddingX = '15px'
  if (props.fixed === true) {
    if (scrolled === true) {
      navbarPosition = 'fixed'
      navbarShadow = useColorModeValue(
        '0px 7px 23px rgba(0, 0, 0, 0.05)',
        'none'
      )
      navbarBg = useColorModeValue(
        'linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)',
        'linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)'
      )
      navbarBorder = useColorModeValue('#FFFFFF', 'rgba(255, 255, 255, 0.31)')
      navbarFilter = useColorModeValue(
        'none',
        'drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))'
      )
    }
  }
  if (props.secondary) {
    navbarBackdrop = 'none'
    navbarPosition = 'absolute'
    secondaryMargin = '22px'
    paddingX = '30px'
  }
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }
  window.addEventListener('scroll', changeNavbar)
  return (
    <Flex
      position={navbarPosition}
      boxShadow={navbarShadow}
      borderColor={navbarBorder}
      borderWidth='1.5px'
      borderStyle='solid'
      transitionDelay='0s, 0s, 0s, 0s'
      transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
      transition-property='box-shadow, background-color, filter, border'
      transitionTimingFunction='linear, linear, linear, linear'
      w={'100%'}
    >
      <Flex
        w='100%'
        flexDirection='row'
      >
        <Box ms='auto' w='100%'>
          <AdminNavbarLinks
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            fixed={props.fixed}
          />
        </Box>
      </Flex>
    </Flex>
  )
}
