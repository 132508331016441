import React ,{ useState, useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'
import {HelioCheckout} from '@heliofi/checkout-react'
import {
  Box,
  Button,
  Flex,
  Text,
  Image,
  Progress,
  Tag,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import {PortfolioModal} from 'components/Portfolio/portfolioModal'



const getTimeRemaining = (endDate) => {
  const total = endDate - new Date();
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  return `${days}d ${hours}h ${minutes}m`;
};

function PortfolioItem ({attr,allPayments,pid,user}) {
  const bgColor = useColorModeValue('gray.50', 'gray.800')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [currDate, setCurrDate] = useState(new Date());

  const invested = user.payments.filter(pay => (pay.paymentStatus !== "Not Paid" && pay.paymentStatus !== "Processing" && pay.relatedPortfolio.id == pid) )
  
  return (
    <Flex  
      alignItems={'center'}
      justifyContent={'space-between'}
      p='12px' bg={bgColor} my='5px' borderRadius='12px'
      _hover={{bg:'gray.100', cursor:'pointer'}}
      onClick={() => {
        if(currDate < new Date(attr.portfolioStartDate)){

          onOpen()
        }
      }}
    >
        <Flex direction='column'>
          
          {currDate < new Date(attr.poolingStartDate) ? (
            <div>
              <Text color='#0C1C2C' fontSize='md' fontWeight='bold' mb='10px'>
            {attr.name} <Tag rounded={'xl'} mb={2}>Coming Soon</Tag>
          </Text>
              <Text fontSize={{ sm: "xs", md: "sm", lg: "xs" }}
            color="gray.400"
            fontWeight="semibold">Pooling in {getTimeRemaining(new Date(attr.poolingStartDate))}</Text>
            </div>
          ) : currDate < new Date(attr.portfolioStartDate) ? (
            <div>
              <Text color='#0C1C2C' fontSize='md' fontWeight='bold' >
            {attr.name} <Tag bg={'blue.100'} rounded={'xl'} mb={2}>Pooling {invested.length > 0 && '(Your Stake: $'+invested.reduce((cumm,curr)=>cumm+curr.amount,0).toLocaleString()+')'}</Tag>
          </Text>
              <Text fontSize={"xs"}
            color="gray.400"
            fontWeight="semibold"> Raised: {allPayments.filter(p => p.relatedPortfolio.id == pid).reduce((cumm,curr)=> cumm+curr.amount,0).toLocaleString()} / {attr.poolingTargetRaise.toLocaleString()}</Text>
            <Progress colorScheme='green' hasStripe value={(((allPayments.filter(p => p.relatedPortfolio.id == pid).reduce((cumm,curr)=> cumm+curr.amount,0))/parseInt(attr.poolingTargetRaise)) *100)} rounded={'full'} />
            <Text fontSize={"xs"}
            color="gray.400"
            fontWeight="semibold">Active in {getTimeRemaining(new Date(attr.portfolioStartDate))}</Text>
            </div>
          ) : currDate < new Date(attr.portfolioEndDate) ? (
            <div>
              <Text color='#0C1C2C' fontSize='md' fontWeight='bold'>
            {attr.name} <Tag bg={'green.100'} rounded={'xl'} mb={2}>Active</Tag>
          </Text>
              <Text fontSize={"xs"}
            color="gray.400"
            fontWeight="semibold"> Raised: {allPayments.filter(p => p.relatedPortfolio.id == pid).reduce((cumm,curr)=> cumm+curr.amount,0).toLocaleString()} USD</Text>
              <Text fontSize={"xs"}
            color="gray.400"
            fontWeight="semibold">Ending in {getTimeRemaining(new Date(attr.portfolioEndDate))}</Text>
            </div>
          ) : currDate < new Date(attr.portfolioUnlockDate) ? (
            <div>
              <Text color='#0C1C2C' fontSize='md' fontWeight='bold' mb='10px'>
            {attr.name} <Tag rounded={'xl'} mb={2}>Completed</Tag>
          </Text>
              <Text fontSize={{ sm: "xs", md: "sm", lg: "xs" }}
            color="gray.400"
            fontWeight="semibold">Unlocked in {getTimeRemaining(new Date(attr.portfolioUnlockDate))}</Text>
            </div>
          ) : (
            <div>
              <Text color='#0C1C2C' fontSize='md' fontWeight='bold' mb='10px'>
            {attr.name} <Tag rounded={'xl'} mb={2}>Unlocked</Tag>
          </Text>
            </div>
          )}
        </Flex>
          <Flex alignItems={'center'}>
          <ReactApexChart
            options={{
              title: {
                text: 'Est. Risk',
                offsetX: 5
              },
              chart: { type: 'radialBar' },
              plotOptions: {
                radialBar: {
                  startAngle: -90,
                  endAngle: 90,
                  track: {
                    background: '#e7e7e7',
                    strokeWidth: '97%'
                  },
                  dataLabels: {
                    name: {
                      show: true,
                      offsetY: 20
                    },
                    value: {
                      show: false
                    }
                  }
                }
              },
              labels: [attr.riskPercentage.toString()+'%'],
              colors: attr.riskPercentage > 70 ? ['#FF0000'] : (attr.riskPercentage < 40 ? ['#48BB78'] : ['#FFA500'])
              
            }}
            series={[attr.riskPercentage]}
            type='radialBar'
            width={100}
          />
          <ReactApexChart
            options={{
              title: {
                text: 'Est. Reward',
                offsetX: -5
              },
              chart: { type: 'radialBar' },
              plotOptions: {
                radialBar: {
                  startAngle: -90,
                  endAngle: 90,
                  track: {
                    background: '#e7e7e7',
                    strokeWidth: '97%'
                  },
                  dataLabels: {
                    name: {
                      show: true,
                      offsetY: 20
                    },
                    value: {
                      show: false
                    }
                  }
                }
              },
              labels: [attr.rewardPercentage.toString()+'%'],
              colors: attr.rewardPercentage > 70 ? ['#48BB78'] : (attr.rewardPercentage < 40 ? ['#ff0000'] : ['#FFA500'])
            }}
            series={[attr.rewardPercentage]}
            type='radialBar'
            width={100}
          />
          </Flex>
           {/* <Button _dark={{bg:'white', color:'#0C1C2C'}} _light={{bg:'#0C1C2C', color:'white'  }} _hover={{opacity: 0.8}} variant='solid' disabled={currDate > new Date(attr.portfolioStartDate) } onClick={onOpen}> Subscribe{disabled && 'd'}</Button> */}
            <PortfolioModal isOpen={isOpen} onClose={onClose} selectedId={pid} />
        
    </Flex>
  )
}

export default PortfolioItem
