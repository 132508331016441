// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button
} from '@chakra-ui/react'
// Custom components
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import TablesProjectRow from 'components/Tables/TablesProjectRow'
import React from 'react'

const Projects = ({ title, captions, data }) => {
  const textColor = useColorModeValue('gray.700', 'white')
  return (
    <Card my='22px' overflowX={{ sm: 'scroll', xl: 'hidden' }}>
      <CardHeader p='6px 0px 22px 0px'>
        <Flex flexDirection='row' justifyContent={'space-between'} alignItems={'center'} w={'full'}>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            {title}
          </Text>
          <a href='https://gitbook.com' target='_blank'>
          <Button variant='solid' _dark={{bg:'white', color:'#0C1C2C'}} _light={{bg:'#0C1C2C', color:'white'  }} _hover={{opacity: 0.8}} rounded='full'>Our Docs</Button></a>
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.8rem' pl='0px'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? '0px' : null}>
                    {caption}
                  </Th>
                )
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, i) => {
              return (
                <TablesProjectRow
                  key={i}
                  title={row.attributes.title}
                  desc={row.attributes.Desc}
                  url={row.attributes.URL}
                />
              )
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export default Projects
