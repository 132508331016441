// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  Icon,
  useColorModeValue,
  Button
} from '@chakra-ui/react'
// Custom components
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import TablesProjectRow from 'components/Tables/TablesProjectRow'
import React from 'react'
const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' });
const AllPortfoliosTable = ({ title, captions, data }) => {
  const textColor = useColorModeValue('gray.700', 'white')
  const currDate = new Date()
  return (
    <Card my='22px' overflowX={{ sm: 'scroll', xl: 'hidden' }}>
      <CardBody>
        <Table variant='striped' color={textColor}>
          <Thead>
            <Tr>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} px={3} py={3}>
                    {caption}
                  </Th>
                )
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.filter(i => i.id != 15).sort((a,b)=>new Date(b.attributes.portfolioStartDate)-new Date(a.attributes.portfolioStartDate)).map((row, i) => {
              console.log
              return (
                <Tr>
      <Td px={3} py={3}>
        <Text fontSize={{base:'xs', md:'md'}} fontWeight={'semibold'}  color={textColor} >
            {row.attributes.name}
          </Text>
      </Td>
      <Td px={3} py={3}>
        <Text fontSize={{base:'xs', md:'md'}} color={textColor} >
        {currDate < new Date(row.attributes.poolingStartDate )? 'Coming Soon' :
  currDate < new Date(row.attributes.portfolioStartDate) ? 'Pooling' :
  currDate < new Date(row.attributes.portfolioEndDate) ? 'Active' :
  currDate < new Date(row.attributes.portfolioUnlockDate) ? 'Completed' 
  : 'Unlocked'}
        </Text>
      </Td>
     <Td px={3} py={3}>
        <Text fontSize={13} color={textColor} >
        {formatter.format(new Date(row.attributes.portfolioStartDate))}
        </Text>
      </Td>
      <Td px={3} py={3}>
        <Text fontSize={13} color={textColor} >
        {formatter.format(new Date(row.attributes.portfolioEndDate))}
        </Text>
      </Td>
      <Td px={3} py={3}>
        <Text fontSize={{base:'xs', md:'md'}} color={textColor} >
        {row.attributes.NAV ? ((row.attributes.NAV[row.attributes.NAV.length -1].y - row.attributes.NAV[0].y)*100).toLocaleString() + '%' : 'none'}
        </Text>
      </Td>
    </Tr>
              )
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export default AllPortfoliosTable
