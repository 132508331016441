import React, {useContext} from 'react';
import { AppState } from "index";
import { NavLink } from 'react-router-dom'
import {
  chakra,
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  Box,
  Input,
  HStack,
  IconButton,
  CloseButton,
  useColorModeValue,
  useDisclosure,
  VStack,
  useColorMode
} from '@chakra-ui/react'
import { AiOutlineMenu } from 'react-icons/ai'
import { BsSun, BsMoonStarsFill } from 'react-icons/bs'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { useHistory } from "react-router-dom";

import LoginIMG from 'assets/img/loginimg.png'
import LOGO from 'assets/logo.png'
import Footer from 'components/Footer/Footer'
export default function Pages(props) {
	const history = useHistory();
	const bg = useColorModeValue('white', 'gray.800')
	const mobileNav = useDisclosure()
	const { colorMode, toggleColorMode } = useColorMode()
	const [isLoading, setLoading] = React.useState(false)
	const [errorMsg, setErrorMsg] = React.useState("")
  const EmailRef = React.createRef()
  const PasswordRef = React.createRef()
  const { login } = useContext(AppState)
  const signIn = async () => {
    if (EmailRef.current.value.length > 5 && PasswordRef.current.value.length > 5) {
      setLoading(true)
      login(EmailRef.current.value, PasswordRef.current.value)
        .then(res => {
          setLoading(false)
          history.push('/admin')
        })
        .catch(error => {
          setLoading(false)
          setErrorMsg(error.data?.error?.message || "Invalid Email or Password")
          console.log(error)
        })
    } else {
      setLoading(false)
      setErrorMsg("Email and Password are Required!")
    }
  }
	return (
		<div>
			<>
      <chakra.header
          position='fixed'
          zIndex={99}
          bg={bg}
          w='full'
          px={{
            base: 2,
            sm: 4
          }}
          py={4}
          shadow='md'
        >
          <Flex alignItems='center' justifyContent='space-between' mx='auto'>
            <Flex>
              <chakra.a
                href='/'
                title='Choc Home Page'
                display='flex'
                alignItems='center'
              >
                <Image maxW={150}  src={LOGO}/>
              </chakra.a>

            </Flex>
            <HStack display='flex' alignItems='center' spacing={1}>
              <HStack
                spacing={1}
                mr={1}
                color='brand.500'
                display={{
                  base: 'none',
                  md: 'inline-flex'
                }}
              >
                <Button variant='ghost' as={'a'} href='/#discover'>Discover</Button>
                <Button variant='ghost' as={'a'} href='/#features'>Features</Button>
                <Button variant='ghost' as={'a'} href='/#faq'>FAQ</Button>
                <NavLink to='/auth'><Button variant='solid'  _light={{bg:'#0C1C2C', color:'white'  }} _hover={{opacity: 0.8}} rounded='full'>Sign in</Button></NavLink>
                {/* <Button
                  onClick={toggleColorMode}
                  _focus={{ boxShadow: 'none' }}
                  w='fit-content'
                  variant='link'
                >
                  {colorMode === 'light' ? <BsMoonStarsFill /> : <BsSun />}
                </Button> */}
              </HStack>
              <Button colorScheme='brand' size='sm'>
                Get Started
              </Button>
              <Box
                display={{
                  base: 'inline-flex',
                  md: 'none'
                }}
              >
                <IconButton
                  display={{
                    base: 'flex',
                    md: 'none'
                  }}
                  aria-label='Open menu'
                  fontSize='20px'
                  color='gray.800'
                  _dark={{
                    color: 'inherit'
                  }}
                  variant='ghost'
                  icon={<AiOutlineMenu />}
                  onClick={() => mobileNav.onOpen()}
                />

                <VStack
                  pos='absolute'
                  top={0}
                  left={0}
                  right={0}
                  display={mobileNav.isOpen ? 'flex' : 'none'}
                  flexDirection='column'
                  p={2}
                  pb={4}
                  m={2}
                  bg={bg}
                  spacing={3}
                  rounded='sm'
                  shadow='sm'
                >
                  <CloseButton
                    aria-label='Close menu'
                    onClick={() => mobileNav.onClose()}
                  />

                  <Button as={'a'} href='/#discover' w='full' variant='ghost'>
                    Dicover
                  </Button>
                  <Button as={'a'} href='/#features' w='full' variant='ghost'>
                    Features
                  </Button>
                  <Button as={'a'} href='/#faq' w='full' variant='ghost'>
                    FAQ
                  </Button>
                  <NavLink to='/auth'><Button w='full' variant='ghost'>
                    Sign in
                  </Button></NavLink>
                </VStack>
              </Box>
            </HStack>
          </Flex>
        </chakra.header>
      </>
      <Flex direction={{base:'column-reverse', lg: 'row'}} m='auto' maxW='8xl'  justifyContent='space-between' px='10'  pt='36'>
      <Box flex={1} p={10}>
        
        
          <Text 
            fontFamily='Inter'
            fontSize={40}
            fontWeight={500}
			color='#2D3748'
          >
            Welcome Back
          </Text>
          <Text 
            fontFamily='Inter'
            fontSize={14}
            fontWeight={500}
			color='#718096'
			mt='10'
          >
            Email
          </Text>
		  <Input variant='filled' placeholder='Your Email' rounded='full' m='1' ref={EmailRef} onKeyDown={(e) => {
    if (e.key === 'Enter') {
      signIn();
    }
  }}/>
		  <Text 
            fontFamily='Inter'
            fontSize={14}
            fontWeight={500}
			color='#718096'
			mt='5'
          >
            Password
          </Text>
		  <Input variant='filled' placeholder='Your Password' type='password' rounded='full' m='1' ref={PasswordRef} onKeyDown={(e) => {
    if (e.key === 'Enter') {
      signIn();
    }
  }} />
		  <Text
              mb='36px'
              ms='4px'
              color='red'
              fontWeight='bold'
              fontSize='14px'>
              {errorMsg &&
                 <span>{errorMsg} <u><a style={{color:'blue'}} href='https://t.me/MrWhiteWolf' target='_blank'>Contact Us for Support</a></u> </span>
              }
            </Text>
            <Flex alignItems={'center'}><Button isLoading={isLoading} onClick={signIn} _dark={{bg:'white', color:'#0C1C2C'}} _light={{bg:'#0C1C2C', color:'white'  }} _hover={{opacity: 0.8}} variant='solid' rounded='full'>Log In</Button>
            <Text as={'a'} href='https://wkulpo6815w.typeform.com/to/aSNc8VEf' ml={5}>Don't Have an Account? <u>Sign Up</u></Text></Flex>
        </Box>
        <Box flex={1}>
          <Image src={LoginIMG}/>
        </Box>
      </Flex>
      
		</div>
	);
}
