// Chakra imports
import { Button, Flex, Text, useColorModeValue, Box } from '@chakra-ui/react'
// Custom components
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import TransactionRow from 'components/Tables/TransactionRow'
import React, { useContext, useEffect, useState } from 'react'
import { AppState } from 'index'
import {
  FaArrowDown,
  FaArrowUp,
  FaClock,
  FaMinus,
  FaExchangeAlt
} from 'react-icons/fa'
const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' });
const Transactions = () => {
  const { user } = useContext(AppState)
  const [page, setpage] = useState([0,0])
 
  return (
    <Card>
      <CardHeader mb='12px'>
        <Flex direction='column' w='100%'>
          <Flex
            direction={{ sm: 'column', lg: 'row' }}
            justify={{ sm: 'center', lg: 'space-between' }}
            align={{ sm: 'center' }}
            w='100%'
            my={{ md: '12px' }}
          >
            <Text
              color={useColorModeValue('gray.700', 'white')}
              fontSize={{ sm: 'lg', md: 'xl', lg: 'lg' }}
              fontWeight='bold'
            >
              Your Transactions
            </Text>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex direction='column' w='100%'>
              {user.payments.length !== 0  && <div>
                <Text
                  color='gray.400'
                  fontSize={{ sm: 'sm', md: 'md' }}
                  fontWeight='semibold'
                  my='12px'
                >
                  Deposits
                </Text>
                <Box h='180px'>
                {user.payments.sort((a,b)=>new Date(a.dueDate)-new Date(b.dueDate)).slice(page[0],page[0]+3).map((row, i) => {
                return (
                  <TransactionRow
                    key={i}
                    name={row.paymentStatus }
                    color={row.paymentStatus == 'Processing' ? 'orange.400' : 'green.400'}
                    logo={row.paymentStatus == 'Processing' ? FaClock : row.paymentStatus == 'Not Paid' ? FaMinus : (row.paymentStatus =='Paid Via PoolMe Wallet'?FaExchangeAlt:FaArrowUp)}
                    date={row.relatedPortfolio.name+' @'+(row.dueDate ? formatter.format(new Date(row.dueDate)) : 'none')}
                    price={(row.paymentStatus =='Paid Via PoolMe Wallet'?'$':'+ $') + row.amount.toLocaleString()}
                  />
                )
                })}
                </Box>
                
                <Flex justifyContent='space-between' alignItems='center' pb={5}>
                <Button variant='link' visibility={(page[0] !== 0)? 'visible': 'hidden'} onClick={()=> {
                  if (page[0] !== 0){
                    const p = [...page]
                    p[0] -= 3
                    setpage(p)
                  }
                  
                }}>← Prev</Button>
                <Button variant='link' visibility={page[0]+3 < user.payments.length ? 'visible': 'hidden'} onClick={()=> {
                  if(page[0]+3 < (user.payments.length)){
                    const p = [...page]
                    p[0] += 3
                    setpage(p)
                  }
                  
                }}>Next →</Button>
                </Flex>
              </div> }
            
              {user.withdrawals.sort((a,b)=>new Date(a.dueDate)-new Date(b.dueDate)).length !== 0 && <div>
                <Text
                  color='gray.400'
                  fontSize={{ sm: 'sm', md: 'md' }}
                  fontWeight='semibold'
                  my='12px'
                >
                  Withdrawals
                </Text>
                <Box h='180px'>
                {user.withdrawals.slice(page[1],page[1]+3).map((row, i) => {
                return (
                  <TransactionRow
                    key={i}
                    name={row.status}
                    color={row.status == 'Awaiting Approval' ? 'orange.400':'red.400'}
                    logo={row.status == 'Awaiting Approval' ? FaClock : row.status == 'Rejected' ? FaMinus : FaArrowDown}
                    date={row.relatedPortfolio.name+' @'+(row.dueDate ? formatter.format(new Date(row.dueDate)) : 'none')}
                    price={'- $' + row.amount.toLocaleString()}
                  />
                )
                })}
                </Box>
                
                <Flex justifyContent='space-between' alignItems='center' pb={5}>
                <Button variant='link' visibility={(page[1] !== 0)? 'visible': 'hidden'} onClick={()=> {
                  if (page[1] !== 0){
                    const p = [...page]
                    p[1] -= 3
                    setpage(p)
                  }
                  
                }}>← Prev</Button>
                <Button variant='link' visibility={page[1]+3 < user.withdrawals.length ? 'visible': 'hidden'} onClick={()=> {
                  if(page[1]+3 < (user.withdrawals.length)){
                    const p = [...page]
                    p[0] += 3
                    setpage(p)
                  }
                  
                }}>Next →</Button>
                </Flex>
              </div>}
            

        </Flex>
      </CardBody>
    </Card>
  )
}

export default Transactions
