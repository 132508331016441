// Chakra imports
import { Flex, Box } from '@chakra-ui/react'
import React, { useContext, useState, useEffect } from 'react'
import Projects from './components/Projects'
import { tablesTableData, dashboardTableData } from 'variables/general'
import { AppState } from 'index'
import avatar1 from 'assets/img/avatars/avatar1.png'

function Tables () {
  const { httpGet, user } = useContext(AppState)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const load = async () => {
    try {
      const resp = await httpGet('/resources')
      setLoading(false)
      setData(resp.data.data)
    } catch (error) {
      setLoading(false)
    }
  }
  useEffect(() => {
    load()
  }, [])

  return (
    <Flex direction='column' alignItems={'center'} pt={{ base: '120px', md: '75px' }} >
      <Box maxW={'8xl'} w='100%'>
      <Projects
        title='Resources'
        captions={['Name', 'Desc', 'URL']}
        data={data}
      />
      </Box>
    </Flex>
  )
}

export default Tables
