import React, { useContext, useState, useEffect } from 'react';
import { AppState } from 'index';
import { HelioCheckout } from '@heliofi/checkout-react';
import {
  Box,
  Button,
  Flex,
  Text,
  Image,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  InputGroup,
  InputLeftElement,
  Tabs,
  TabList,
  Tag,
  TabPanels,
  TabPanel
} from '@chakra-ui/react';
import WalletQR from 'assets/img/sol.png';
import Markdown from 'markdown-to-jsx';
const getTimeRemaining = (endDate) => {
  const total = endDate - new Date();
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  return `${days}d ${hours}h ${minutes}m`;
};
import { QRCode } from 'react-qrcode-logo';
export const PortfolioModal = ({ isOpen, onClose, selectedId }) => {
  const { httpGet, user } = useContext(AppState);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(selectedId);
  const [loading, setLoading] = useState(true);

  const load = async () => {
    try {
      const resp = await httpGet('/portfolios?populate=*');
      setData(resp.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    setSelected(selectedId);
  }, [selectedId]);

  const [val, setVal] = React.useState(2000);
  const currDate = new Date();
  const helioConfig = {
    paylinkId: "666ef93d82a5a91033cd204d",
    amount: val.toString(),
    display: "button",
    network: "main",
    theme: { colors: { primaryButtonBackground: '#000', primaryButtonBackgroundHover: '#gray' } },
    customTexts: {
      mainButtonTitle: 'Invest With Wallet Or Card'
    },
    onSuccess: event => console.log(event),
    onError: event => console.log(event),
    onPending: event => console.log(event),
    onCancel: () => console.log("Cancelled payment"),
    onStartPayment: () => console.log("Starting payment"),
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={'5xl'}>
      <ModalOverlay />
      <ModalContent >
        <ModalHeader>Subscribe or Add Funds:</ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH={'75vh'} overflowX={'scroll'}>
          {!loading && data.length !== 0 &&
            <Flex flexDir={'column'}>
              <Text fontWeight={'bold'}>Porfolio:</Text>
              <Select value={selected} onChange={e => setSelected(e.target.value)}>
                {data.filter(i => i.id == 15 || currDate < new Date(i.attributes.portfolioStartDate)).map((p, i) => (
                  <option key={i} value={p.id}>{p.attributes.name}</option>
                ))}
              </Select>
              {data.filter(p => parseInt(p.id) === parseInt(selected)).map((p, i) => (
                <div>
                <Flex direction={{base:'column', md:'row'}}>
                <Flex direction={'column'} width={{base:'full',md:'48%'}} alignItems={'center'} >
                <img src={p.attributes.bannerImage.data.attributes.url} style={{
                  borderRadius:20,
                  marginTop: 15,
                  width:'100%',
                  paddingBottom:'20px'
                }}/>
                
               
                { p.id !== 15 &&<Box h={250} px={8} overflow={'scroll'}>
                <Text fontWeight={'bold'} >Description:</Text>
                  <Markdown options={{ forceBlock: true }}>{p.attributes.description}</Markdown>
                
                </Box>}


                </Flex>
                <Flex direction={'column'}  width={{base:'full',md:'48%'}} mx={5} mt={5}>
                
                
                
                <Flex direction={'column'} alignItems={'center'}>
               { p.id !== 15 && <Flex flexWrap={'wrap'} alignItems={'center'}>
                {currDate < new Date(p.attributes.poolingStartDate) ? (
                  <>
                    <Tag m={2}>Coming Soon</Tag>
                    <Tag m={2}>Target Raise:&nbsp;<b>{p.attributes.poolingTargetRaise.toLocaleString()}</b></Tag>
                    <Tag m={2}>Pooling starts in {getTimeRemaining(new Date(p.attributes.poolingStartDate))}</Tag>
                  </>
                ) : currDate < new Date(p.attributes.portfolioStartDate) ? (
                  <>
                    <Tag m={2}>Pooling</Tag>
                    <Tag m={2}>Target Raise:&nbsp;<b>{p.attributes.poolingTargetRaise.toLocaleString()}</b></Tag>
                    <Tag m={2}>Active in {getTimeRemaining(new Date(p.attributes.portfolioStartDate))}</Tag>
                  </>
                ) : currDate < new Date(p.attributes.portfolioEndDate) ? (
                  <>
                    <Tag m={2}>Active</Tag>
                    <Tag m={2}>Ending in {getTimeRemaining(new Date(p.attributes.portfolioEndDate))}</Tag>
                    
                  </>
                ) : currDate < new Date(p.attributes.portfolioUnlockDate) ? (
                  <>
                    <Tag m={2}>Completed</Tag>
                    <Tag m={2}>Funds Unlocked in {getTimeRemaining(new Date(p.attributes.portfolioUnlockDate))}</Tag>
                  </>
                ) : (
                  <>
                    <Tag m={2}>Unlocked</Tag>
                  </>
                )}
                <Tag m={2}>Lockup Period:&nbsp;<b>{p.attributes.lockupPeriodDescription}</b></Tag>
                <Tag m={2}>Management Fees:&nbsp;<b>{p.attributes.managementFees}%</b></Tag>
                <Tag m={2}>Performance Fees:&nbsp;<b>{p.attributes.performanceFees}%</b></Tag>
                <Tag m={2}>Estimated Risk:&nbsp;<b>{p.attributes.riskPercentage}%</b></Tag>
                <Tag m={2}>Estimated Reward:&nbsp;<b>{p.attributes.rewardPercentage}%</b></Tag>
                <Tag m={2}>Max Drawdown:&nbsp;<b>{p.attributes.maxDrawdown}%</b></Tag>
                <Tag m={2}>Max Investment Ticket:&nbsp;<b>${p.attributes.maxInvestmentTicket?.toLocaleString()}</b></Tag>
                <Tag m={2}>Min Investment Ticket:&nbsp;<b>${p.attributes.minInvestmentTicket?.toLocaleString()}</b></Tag>
               </Flex>}
                <Flex alignItems={'center'} direction={'column'} w={{base:'100%',md:'65%'}} py={3} >
                <QRCode eyeRadius={50}  logoImage={WalletQR} qrStyle='dots' value={p.attributes?.portfolioOnboardingWallet || '4ppPjRHuPn2uJaZ8YMWZJRxmwYJTc7sbkjSgckojamRT'} />
                
                
                <Text textAlign={'center'} fontWeight={'bold'}>Send SOL to the Portfolio Wallet</Text>
                <Text textAlign={'center'} bg={'blue.100'} fontSize={'xs'}>{p.attributes?.portfolioOnboardingWallet || '4ppPjRHuPn2uJaZ8YMWZJRxmwYJTc7sbkjSgckojamRT'}</Text>
                {/* <Text textAlign={'center'}> -- OR -- </Text>
                <HelioCheckout config={helioConfig} />
                <InputGroup  p={1} display={'flex'} alignItems={'center'}>
                              <InputLeftElement pointerEvents='none' color='gray.300' fontSize='1.2em' pt={2}>$</InputLeftElement>
                              <Input w={'100%'} value={val} onChange={e => setVal(e.target.value)} type='number' />
                          </InputGroup>
                   */}
                  <Text textAlign={'center'} py={3}> -- OR -- </Text>
                  <Button as={'a'} href='https://t.me/MrWhiteWolf' target='_blank' w={'100%'} rounded={'lg'} _dark={{bg:'white', color:'#0C1C2C'}} _light={{bg:'#000', color:'white'  }} >
                    INVEST WITH CASH
                  </Button>
                 
                </Flex>
                </Flex>
                
                
                </Flex>
                </Flex>
                
                
                
              
              </div>
              ))}
            </Flex>
          }
        </ModalBody>
        <ModalFooter>
          <Button variant='ghost' onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
