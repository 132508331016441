// Chakra imports
import {
  Flex,
  Grid,
  SimpleGrid,
  useColorModeValue
} from '@chakra-ui/react'
// assets

import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon
} from 'components/Icons/Icons.js'
import { AppState } from 'index'
import React, { useContext, useEffect, useState } from 'react'
import { newestTransactions, olderTransactions } from 'variables/general'
import ActiveUsers from './components/ActiveUsers'
import MiniStatistics from './components/MiniStatistics'
// import { dashboardTableData, timelineData } from 'variables/general'
// import OrdersOverview from './components/OrdersOverview'
// import Projects from './components/Projects'
import Portfolios from './components/Portfolios'
import SalesOverview from './components/SalesOverview'
import { NavLink, useLocation,useHistory } from "react-router-dom";

import Transactions from './components/Transactions'

export default function Dashboard () {
  
  const { user } = useContext(AppState)
  const [totalInv, setTotalInv] = useState(0)
  const [totalBal, setTotalBal] = useState(0)
  const [totaLocked, setTotalLocked] = useState(0)
  const [totaUnlocked, setTotalUnlocked] = useState(0)
  const iconBoxInside = useColorModeValue('white', 'white')
  const history = useHistory()
  useEffect(()=>{
    if(user.subscribedTo.length === 0) {
      history.push('/admin/portfolios')
    }

    const currDate = new Date()
    const verifiedPayments = user.payments.filter(pay => (pay.paymentStatus !== "Not Paid" && pay.paymentStatus !== "Processing") ) // all payments that are paid

    setTotalInv(verifiedPayments
      .filter(p => 
        currDate > new Date(p.relatedPortfolio.portfolioStartDate) &&
        currDate < new Date(p.relatedPortfolio.portfolioEndDate)
      ) // only Pooling and active
      .reduce((cum,cur) => cum+cur.amount, 0) // sum
    );
    
    setTotalBal(verifiedPayments
      .filter(p => 
        currDate > new Date(p.relatedPortfolio.portfolioStartDate) &&
        currDate < new Date(p.relatedPortfolio.portfolioEndDate)
      ) // only Pooling and active
      .map(p => 
        (p.amount/(p.subscriptionNAV || 1)) * 
        (p.relatedPortfolio.NAV[p.relatedPortfolio.NAV.length - 1].y)
      ) // changes array of payments from objects into numbers(pnl of each)
      .reduce((cum,cur) => cum+cur, 0) // sum
    )
    
    const internalTransfers = user.payments.filter(pay => (pay.paymentStatus === "Paid Via PoolMe Wallet")).reduce((cum,cur) => cum+cur.amount, 0)
    const withdr = user.withdrawals.filter(pay => (pay.status === "Paid")).reduce((cum,cur) => cum+cur.amount, 0)

    setTotalLocked(verifiedPayments
      .filter(p => 
        currDate > new Date(p.relatedPortfolio.portfolioEndDate) &&
        currDate < new Date(p.relatedPortfolio.portfolioUnlockDate)
      ) // only Completed
      .map(p => 
        (p.amount/(p.subscriptionNAV || 1)) * 
        (p.relatedPortfolio.NAV[p.relatedPortfolio.NAV.length - 1].y || 1)
      ) // changes array of payments from objects into numbers(pnl of each)
      .reduce((cum,cur) => cum+cur, 0) // sum
    )

    setTotalUnlocked(verifiedPayments
      .filter(p =>
        currDate > new Date(p.relatedPortfolio.portfolioUnlockDate)
      ) // only Unlocked
      .map(p => 
        (p.amount/(p.subscriptionNAV || 1)) * 
        (p.relatedPortfolio.NAV[p.relatedPortfolio.NAV.length - 1].y || 1)
      ) // changes array of payments from objects into numbers(pnl of each)
      .reduce((cum,cur) => cum+cur, 0) - internalTransfers - withdr // sum - paid withdrawals and payments that are Paid via Internal Portfolio Transfer
    )
  
  },[user])

  return (
    <Flex flexDirection='column' pt={{ base: '120px', md: '75px' }} >
      <SimpleGrid columns={{ base: 2,  lg:4 }} spacing='24px' mb={'26px'}>
        <MiniStatistics
          title={"Active Investments"}
          amount={totalInv}
        />
        <MiniStatistics
          title={"Estimated Active Balance"}
          amount={totalBal}
          percentage={((totalBal-totalInv)/(totalInv || 1))*100}
          icon={<GlobeIcon h='24px' w='24px' color={iconBoxInside} />}
        />
        <MiniStatistics
          title='Locked Funds'
          amount={totaLocked}
          icon={<DocumentIcon h='24px' w='24px' color={iconBoxInside} />}
        />
        <MiniStatistics
          title='Unlocked Funds'
          amount={totaUnlocked}
          icon={<CartIcon h='24px' w='24px' color={iconBoxInside} />}
        />
        
      </SimpleGrid>
      
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing='24px' mb={'26px'}>
      <ActiveUsers/>
      <SalesOverview />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing='24px' mb={'26px'}>

        <Transactions
          title='Your Transactions'
          newestTransactions={newestTransactions}
          olderTransactions={olderTransactions}
        />
        <Portfolios />
      </SimpleGrid>
    </Flex>
  )
}
