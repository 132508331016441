import React from 'react'
import {
  Tr,
  Td,
  Flex,
  Text,
  Progress,
  Icon,
  Button,
  useColorModeValue
} from '@chakra-ui/react'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { GrArticle } from 'react-icons/gr'

function DashboardTableRow (props) {
  const textColor = useColorModeValue('gray.700', 'white')
  return (
    <Tr>
      <Td minWidth={{ sm: '250px' }} pl='0px'>
        <Flex alignItems='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Icon as={GrArticle} h='24px' w='24px' me='18px' />
          <Text
            fontSize='md'
            color={textColor}
            fontWeight='bold'
            minWidth='100%'
          >
            {props.title}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text fontSize='md' color={textColor} fontWeight='bold' pb='.5rem'>
          {props.desc}
        </Text>
      </Td>

      <Td>
        <a href={props.url} target='_blank' rel='noreferrer'>
          <Button p='0px' bg='transparent'>
            <Icon as={FaExternalLinkAlt} color='gray.400' cursor='pointer' />
          </Button>
        </a>
      </Td>
    </Tr>
  )
}

export default DashboardTableRow
