// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  Icon,
  useColorModeValue,
  Button
} from '@chakra-ui/react'
// Custom components
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import TablesProjectRow from 'components/Tables/TablesProjectRow'
import React from 'react'

const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' });

const TrxTable = ({ title, captions, data }) => {
  const textColor = useColorModeValue('gray.700', 'white')
  return (
    <Card my='22px' overflowX={{ sm: 'scroll', xl: 'hidden' }}>
      <CardBody>
        <Table variant='striped' color={textColor}>
          <Thead>
            <Tr>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} px={3} py={3}>
                    {caption}
                  </Th>
                )
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.sort((a,b)=>new Date(b.dueDate)-new Date(a.dueDate)).map((row, i) => {
              console.log
              return (
                <Tr>
     
      <Td px={3} py={3}>
        <Text fontSize={{base:'sm', md:'md'}} fontWeight={'bold'} color={textColor}  >
        {row.relatedPortfolio.name}
        </Text>
      </Td>
      <Td px={3} py={3}>
        <Text fontSize={{base:'xs', md:'md'}} color={textColor} >
          {row.paymentStatus ? row.paymentStatus :  row.status}
        </Text>
      </Td>
      <Td px={3} py={3}>
        <Text fontSize={13} color={textColor}  >
          {row.dueDate ? formatter.format(new Date(row.dueDate)) : 'none'}
        </Text>
      </Td>
      <Td px={3} py={3}>
        <Text fontSize={13} color={textColor} >
          {row.paidAt ? formatter.format(new Date(row.paidAt)): 'none'}
        </Text>
      </Td>
      <Td px={3} py={3}>
        <Text fontSize={{base:'xs', md:'md'}} color={textColor}  >
          {row.paymentStatus ? row.paymentStatus =='Paid Via PoolMe Wallet' ? 'Internal Transfer' : 'Deposit' : 'Withdrawal'}
        </Text>
      </Td>
      <Td px={3} py={3}>
        <Text fontSize={{base:'xs', md:'md'}} color={textColor} >
          {row.paymentStatus 
          ? row.paymentStatus =='Paid Via PoolMe Wallet' ? '↔ $'+row.amount.toLocaleString()  :  '+ $'+row.amount.toLocaleString() 
          : '- $'+row.amount.toLocaleString()}
        </Text>
      </Td>

      
      <Td px={3} py={3}>
        <Text fontSize={{base:'xs', md:'md'}} color={textColor} >
          {row.userNote || 'none' }
        </Text>
      </Td>
    </Tr>
              )
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export default TrxTable
