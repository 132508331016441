// Chakra imports
import { Box, Flex, Text, useColorModeValue,Tabs, Tab, TabList, TabPanels, TabPanel,SimpleGrid, Stat, StatLabel, StatNumber } from '@chakra-ui/react'
import ReactApexChart from 'react-apexcharts'
// Custom components
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import React, {useState} from 'react'
import { AppState } from 'index'


import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon
} from 'components/Icons/Icons.js'
import MiniStatistics from '../components/MiniStatistics'


const SalesOverview = ({ title, chart }) => {
  const [data, setData] = React.useState([])
  const { user } = React.useContext(AppState)
  const iconBoxInside = useColorModeValue('white', 'white')
  React.useEffect(() => {
    const verifiedPayments = user.payments.filter(pay => (pay.paymentStatus !== "Not Paid" && pay.paymentStatus !== "Processing") )

    setData(user.subscribedTo.filter(p => new Date() > new Date(p.portfolioStartDate) && new Date() < new Date(p.portfolioEndDate)).map(s => ({
      name: s.name,
      total: verifiedPayments.filter(p => p.relatedPortfolio.id == s.id).reduce((cum,cur) => cum+cur.amount, 0),
      balance: verifiedPayments.filter(p => p.relatedPortfolio.id == s.id).reduce((cum,payment) => (cum + (payment.amount/(payment.subscriptionNAV || 1))*payment.relatedPortfolio.NAV[payment.relatedPortfolio.NAV.length - 1].y), 0),
      data: s.NAV
    })))
  }, [])

  return (
    <Card>
      <CardBody>
  {data.length > 0 ?  <Tabs style={{ width: '100%' }} variant='enclosed'>
          <TabList>
            {
              data.map((item, i) => (
                <Tab key={i}>{item.name}</Tab>
              ))
            }
          </TabList>
          <TabPanels>
            {
               data.map((item, i) => (
                 <TabPanel key={i} style={{ width: '100%', height: 400 }}>
<Flex direction={'column'} w='100%' h={'85%'} justifyContent={'space-between'} >
<ReactApexChart
      series={[item]}
      options={{
        chart: {
          height: 350,
          type: 'line'
        },
        dataLabels: {
          enabled: false
        },
        theme:{
          mode: useColorModeValue('light','dark')
        },
        xaxis: {
          type: 'date'
        },
      }}
      type='line'
      width='100%'
      height='100%'
    />
    <hr style={{padding:7}}/>
    <SimpleGrid columns={2} >
        <Stat borderRight={'1px'} borderRightColor={'#e1e1e1'}>
            <StatLabel
              fontFamily='Inter'
              fontSize='sm'
              color='gray.400'
              fontWeight='bold'
              pb='.1rem'>
              Total Investments
            </StatLabel>
            
              <StatNumber fontSize='md' color={"gray.700"}>
              {item.total.toLocaleString()} USD
              </StatNumber>
             
            
          </Stat>
          <Stat pl={10} >
            <StatLabel
              fontFamily='Inter'
              fontSize='sm'
              color='gray.400'
              fontWeight='bold'
              pb='.1rem'>
              Estimated Balance
            </StatLabel>
            <StatNumber fontSize='md' color={"gray.700"}>
              {item.balance.toLocaleString()} USD
              </StatNumber>
            
              <StatNumber
                color={(((parseFloat(item.balance) - parseFloat(item.total))/ parseFloat(item.total)) * 100) > 0 ? "green.400" : "red.400"}
                fontWeight='bold'
                fontSize='xs'>
                  <Text as={'span'} color={"gray.400"}>P&L: </Text>
                  {(parseFloat(item.balance) - parseFloat(item.total)).toLocaleString()+' USD ('+(((parseFloat(item.balance) - parseFloat(item.total))/ parseFloat(item.total)) * 100).toLocaleString()+'%)'}
              </StatNumber>
             
            
          </Stat>
        
      </SimpleGrid>
      </Flex>
</TabPanel>
               ))
            }
          </TabPanels>
        </Tabs>: (<Text textAlign={'center'} p={10} w={'100%'}>No Invested Active Portfolios</Text>)}

      </CardBody>
    </Card>

  )
}

export default SalesOverview
